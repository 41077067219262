export default {
  LOGIN_PAGE: "/login",
  HOME_PAGE: "/",
  ERROR_AUTH: "/auth-error",

  // Categories
  //

  CATEGORIES_GROUPS_LIST: "/categories/groups/list/:page",
  CATEGORIES_GROUP_CREATE: "/categories/group/create",
  CATEGORIES_GROUP_EDIT: "/categories/group/edit/:id",

  CATEGORIES_LIST: "/categories/list/:page",
  CATEGORY_CREATE: "/category/create",
  CATEGORY_EDIT: "/category/edit/:id",

  // Templates
  //

  TEMPLATES_LIST: "/templates/list/:page",
  TEMPLATE_CREATE: "/template/create",
  TEMPLATE_EDIT: "/template/edit/:id",
  TEMPLATE_QUICK_EDIT: "/template/quick-edit/:page",

  TEMPLATES_STATISTICS: "/templates/statistics/list/",

  // Holidays

  HOLIDAYS_LIST: "/holidays/list/:page",
  HOLIDAYS_CREATE: "/holidays/create",
  HOLIDAYS_EDIT: "/holidays/edit/:id",

  // Content Plans

  PLANS_LIST: "/content-plans/list/:page",
  PLANS_ITEMS: "/content-plan-items/:id",

  // Keywords

  KEYWORDS_LIST: "/keywords/list/:page",
  KEYWORDS_CREATE: "/keywords/create",
  KEYWORDS_CREATE_ARRAY: "/keywords/create-array",
  KEYWORDS_EDIT: "/keywords/edit/:id",

  // References
  //

  TEMPLATES_TYPES_LIST: "/references/templates-types/list/:page",
  TEMPLATES_TYPE_CREATE: "/references/templates-type/create",
  TEMPLATES_TYPE_EDIT: "/references/templates-type/edit/:id",

  TEMPLATES_STATUSES_LIST: "/references/templates-statuses/list/:page",
  TEMPLATES_STATUS_CREATE: "/references/templates-status/create",
  TEMPLATES_STATUS_EDIT: "/references/templates-status/edit/:id",

  TEMPLATES_FORMATS_LIST: "/references/templates-formats/list/:page",
  TEMPLATES_FORMAT_CREATE: "/references/templates-format/create",
  TEMPLATES_FORMAT_EDIT: "/references/templates-format/edit/:id",

  TEMPLATES_TAGS_LIST: "/references/templates-tags/list/:page",
  TEMPLATES_TAG_CREATE: "/references/templates-tag/create",
  TEMPLATES_TAG_EDIT: "/references/templates-tag/edit/:id",

  TEMPLATES_STYLE_LIST: "/references/templates-style/list/:page",
  TEMPLATES_STYLE_CREATE: "/references/templates-style/create",
  TEMPLATES_STYLE_EDIT: "/references/templates-style/edit/:id",

  TEMPLATES_COLOR_LIST: "/references/templates-color/list/:page",
  TEMPLATES_COLOR_CREATE: "/references/templates-color/create",
  TEMPLATES_COLOR_EDIT: "/references/templates-color/edit/:id",

  TEMPLATES_FONTS_LIST: "/references/templates-fonts/list/:page",
  TEMPLATES_FONT_CREATE: "/references/templates-font/create",
  TEMPLATES_FONT_EDIT: "/references/templates-font/edit/:id",

  TEMPLATES_SIZES_LIST: "/references/templates-sizes/list/:page",
  TEMPLATES_SIZES_CREATE: "/references/templates-size/create",
  TEMPLATES_SIZES_EDIT: "/references/templates-size/edit/:id",

  LAYOUT_FONTS_LIST: "/references/layout-fonts/list/:page",
  LAYOUT_FONTS_CREATE: "/references/layout-fonts/create",
  LAYOUT_FONTS_EDIT: "/references/layout-fonts/edit/:id",

  TEMPLATES_ATTRIBUTES_LIST: "/references/templates-attribute/list/:page",
  TEMPLATES_ATTRIBUTES_CREATE: "/references/templates-attribute/create",
  TEMPLATES_ATTRIBUTES_EDIT: "/references/templates-attribute/edit/:id",

  // Dictionary
  //

  DICTIONARY: "/dictionary/list/:page",
  DICTIONARY_DETAIL: "/dictionary/detail/:id",

  // Blog
  //

  BLOG_GROUPS_LIST: "/blog/groups/list/:page",
  BLOG_GROUP_CREATE: "/blog/group/create",
  BLOG_GROUP_EDIT: "/blog/group/edit/:id",

  BLOG_CATEGORIES_LIST: "/blog/categories/list/:page",
  BLOG_CATEGORY_CREATE: "/blog/category/create",
  BLOG_CATEGORY_EDIT: "/blog/category/edit/:id",

  BLOG_POSTS_LIST: "/blog/posts/list/:page",
  BLOG_POST_CREATE: "/blog/post/create",
  BLOG_POST_EDIT: "/blog/post/edit/:id",

  BLOG_POST_STATUSES_LIST: "/blog/blog-statuses/list/:page",
  BLOG_POST_STATUS_CREATE: "/blog/blog-status/create",
  BLOG_POST_STATUS_EDIT: "/blog/blog-status/edit/:id",

  // Users
  //

  USERS_LIST: "/users/list/:page",
  USER_CREATE: "/user/create",
  USER_EDIT: "/user/edit/:id",
  USER_DETAIL: "/user/detail/:id",

  USER_GROUPS_LIST: "/user/groups/list/:page",
  USER_GROUP_CREATE: "/user/group/create",
  USER_GROUP_EDIT: "/user/group/edit/:id",

  // Subscription
  //

  SUBSCRIPTION_LIST: "/subscription/list/:page",
  SUBSCRIPTION_CREATE: "/subscription/create",
  SUBSCRIPTION_EDIT: "/subscription/edit/:id",

  // FAQ
  //

  FAQ_GROUP_LIST: "/faq/group/list/:page",
  FAQ_GROUP_CREATE: "/faq/group/create",
  FAQ_GROUP_EDIT: "/faq/group/edit/:id",

  FAQ_ITEM_LIST: "/faq/item/list/:page",
  FAQ_ITEM_CREATE: "/faq/item/create",
  FAQ_ITEM_EDIT: "/faq/item/edit/:id",

  // Static
  //

  STATIC_LIST: "/static/list/:page",
  STATIC_CREATE: "/static/create",
  STATIC_EDIT: "/static/edit/:id",

  // Social
  //

  SOCIAL_FB: "/social/fb/:page",

  // Indexing
  //

  INDEXING_LIST: "/indexing/list/:page",

  EDITOR: "/template-layout/:id",

  REMOVER_CROP: "/remover-crop",

  ONLY_CROP: "/image-crop",

  // Lang
  //

  LANG_LIST: "/lang/list/:page",
  LANG_CREATE: "/lang/create",
  LANG_EDIT: "/lang/edit/:id",

  // SEO
  //

  SEO_LIST: "/seo/list/:page",
  SEO_CREATE: "/seo/create",
  SEO_EDIT: "/seo/edit/:id",
};
